
/* Footer Styling */
.footer_section {
    background-color: #232323;
    color: #fff;
    padding: 50px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
  }
  
  .footer-logo-img {
    width: 120px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .footer-logo-img:hover {
    transform: scale(1.1);
  }
  
  .about_text {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .location_text {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    transition: color 0.3s ease;
  }
  
  .location_text:hover {
    color: #f3a847;
  }
  
  .location_text i {
    font-size: 18px;
    margin-right: 10px;
  }
  
  .dolor_text {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .footer_images {
    display: flex;
    justify-content: space-between;
  }
  
  .image_12 img {
    width: 60px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
  }
  
  .image_12 img:hover {
    transform: scale(1.1);
  }
  
  .mail_text {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subscribe_bt a {
    display: inline-block;
    background-color: #f3a847;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .subscribe_bt a:hover {
    background-color: #e68a1e;
  }
  
  .footer_social_icon ul {
    display: flex;
    gap: 20px;
  }
  
  .footer_social_icon li a {
    font-size: 24px;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .footer_social_icon li a:hover {
    color: #f3a847;
  }
  
  .copyright_section {
    background-color: #1a1a1a;
    padding: 15px 0;
    text-align: center;
  }
  
  .copyright_text {
    font-size: 14px;
    color: white;
    font-size: larger;
  }
  
  .footer-link {
    color: #f3a847;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  footer {
    background-color: rgb(3, 26, 50);
    color: white;
    display: grid;
    grid-template-columns: 125px 400px 200px 300px;
    padding: 150px;
    grid-auto-flow: column;
    column-gap: 5px;
    
    transition: background-color 0.3s; /* Smooth background color transition */
}

footer:hover {
    background-color: rgb(5, 30, 60); /* Darken on hover */
}

@media (max-width: 1450px) {
    footer {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, auto);
        text-align: center;
    }
}

ul {
    padding: 0;
}

ul li {
    list-style: none;
    font-size: large;
    color: rgb(189, 182, 182);
    margin-bottom: 10px;
    transition: color 0.3s, transform 0.2s; /* Transition for color and scale */
}

ul li:hover {
    color: rgb(255, 165, 0); /* Change color on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}

.bold {
    font-weight: bolder;
    font-size: xx-large;
    color: white;
}

.logo-container {
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Center vertically */
    margin-bottom: 150px; /* Space below logo */
}

.footer-logo {
    width: 50px; /* Set width for the logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between logo and text */
}

.container4 {
    display: flex;
    flex-direction: column; /* Ensure items stack vertically */
}

.subscribe-container {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center vertically */
    transition: transform 0.2s; /* Smooth transition for the container */
}

.container4 input {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid greenyellow;
    border-radius: 5px;
    transition: border 0.3s, box-shadow 0.3s; /* Transition for border and shadow */
    width: 250px; /* Set width to match button */
}

.container4 input:focus {
    border-color: orange; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Add shadow on focus */
}

#submit {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 40px; /* Set width of the button to fit the image */
    height: 40px; /* Maintain button height */
    transition: transform 0.3s, box-shadow 0.3s; /* Transition for button */
}

#submit img {
    width: 100%; /* Make the image fill the button */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s; /* Transition for image */
    background-color: green;
}

#submit:hover {
    transform: scale(1.1); /* Slightly enlarge the button on hover */
    box-shadow: 0 0 10px rgba(29, 28, 27, 0.7); /* Add shadow on hover */
}

.small {
    font-size: 70%;
    color: white;
}

hr {
    width: 86vw;
    border: none;
    border-top: 1px solid rgb(189, 182, 182);
    margin: 20px 0;
}

.copyright {
    text-align: center;
    color: rgb(189, 182, 182);
    margin-top: 20px;
}

.copyright a {
    color: white;
    text-decoration: none;
    transition: color 0.3s, text-shadow 0.3s; /* Add text-shadow on hover */
}

.copyright a:hover {
    color: orange; /* Change link color on hover */
    text-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Add shadow effect */
}
/* General Footer Styles */
footer {
  background-color: rgb(3, 26, 50);
  color: white;
  display: grid;
  grid-template-columns: 125px 400px 200px 300px;
  padding: 150px;
  grid-auto-flow: column;
  column-gap: 5px;
  
  transition: background-color 0.3s; /* Smooth background color transition */
}

footer:hover {
  background-color: rgb(5, 30, 60); /* Darken on hover */
}

ul {
  padding: 0;
  list-style: none;
}

ul li {
  font-size: large;
  color: rgb(189, 182, 182);
  margin-bottom: 10px;
  transition: color 0.3s, transform 0.2s; /* Transition for color and scale */
}

ul li:hover {
  color: rgb(255, 165, 0); /* Change color on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.bold {
  font-weight: bolder;
  font-size: xx-large;
  color: white;
}

.logo-container {
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center vertically */
  margin-bottom: 150px; /* Space below logo */
}

.footer-logo {
  width: 50px; /* Set width for the logo */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between logo and text */
}

.container4 {
  display: flex;
  flex-direction: column; /* Ensure items stack vertically */
}

.subscribe-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  transition: transform 0.2s; /* Smooth transition for the container */
}

.container4 input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid greenyellow;
  border-radius: 5px;
  transition: border 0.3s, box-shadow 0.3s; /* Transition for border and shadow */
  width: 250px; /* Set width to match button */
}

.container4 input:focus {
  border-color: orange; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Add shadow on focus */
}

#submit {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 40px; /* Set width of the button to fit the image */
  height: 40px; /* Maintain button height */
  transition: transform 0.3s, box-shadow 0.3s; /* Transition for button */
}

#submit img {
  width: 100%; /* Make the image fill the button */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s; /* Transition for image */
  background-color: green;
}

#submit:hover {
  transform: scale(1.1); /* Slightly enlarge the button on hover */
  box-shadow: 0 0 10px rgba(29, 28, 27, 0.7); /* Add shadow on hover */
}

.small {
  font-size: 70%;
  color: white;
}

hr {
  width: 86vw;
  border: none;
  border-top: 1px solid rgb(189, 182, 182);
  margin: 20px 0;
}

.copyright {
  text-align: center;
  color: rgb(189, 182, 182);
  margin-top: 20px;
}

.copyright a {
  color: white;
  text-decoration: none;
  transition: color 0.3s, text-shadow 0.3s; /* Add text-shadow on hover */
}

.copyright a:hover {
  color: orange; /* Change link color on hover */
  text-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Add shadow effect */
}

/* Footer Social Styles */
.footer-social ul {
  display: flex;
  flex-direction: column; /* Stack icons vertically */
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.footer-social ul li a {
  font-size: 24px;
  color: white;
  transition: color 0.3s ease;
}

.footer-social ul li a:hover {
  color: #f3a847;
}

/* Label Social Media Section */
.footer-social h3 {
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1450px) {
    footer {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, auto);
        text-align: center;
    }
}

@media (max-width: 768px) {
  footer {
    padding: 30px 20px;
  }

  footer .footer-logo {
    width: 60px;
  }

  footer .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  footer .footer-social ul {
    flex-direction: column;
    gap: 15px;
  }

  footer .footer-social ul li a {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  footer {
    padding: 20px 10px;
  }

  footer .footer-logo {
    width: 50px;
  }

  footer .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  footer .footer-social ul li a {
    font-size: 24px;
  }
}
/* General Footer Styling */
footer {
  background-color: rgb(3, 26, 50);
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 100px;
  grid-gap: 40px;
  justify-items: start; /* Align items to start */
  transition: background-color 0.3s;
}

footer:hover {
  background-color: rgb(5, 30, 60);
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
  font-size: large;
  color: rgb(189, 182, 182);
  margin-bottom: 10px;
  transition: color 0.3s, transform 0.2s;
}

ul li:hover {
  color: rgb(255, 165, 0);
  transform: translateY(-2px);
}

.bold {
  font-weight: bolder;
  font-size: xx-large;
  color: white;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.footer-logo {
  width: 60px;
  height: auto;
  margin-right: 10px;
}

/* Footer Social Section */
.footer-social ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-social ul li a {
  font-size: 24px;
  color: white;
  transition: color 0.3s ease;
}

.footer-social ul li a:hover {
  color: #f3a847;
}

/* Media Queries for Responsiveness */

/* Tablet Screens */
@media (max-width: 768px) {
  footer {
    padding: 50px 20px;
    grid-template-columns: 1fr;
    text-align: center;
  }

  footer .footer-logo {
    width: 70px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .container1, .container2, .container3, .footer-social {
    width: 100%;
    text-align: left; /* Align left for smaller screens */
  }

  .footer-social ul {
    flex-direction: row;
    gap: 10px;
  }
}

/* Mobile Screens */
@media (max-width: 480px) {
  footer {
    padding: 20px 10px;
  }

  footer .footer-logo {
    width: 50px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer-social ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-social ul li a {
    font-size: 22px;
  }

  .container1, .container2, .container3, .footer-social {
    text-align: left; /* Ensure everything is aligned left */
  }
}
/* General Footer Styles */
footer {
  background-color: rgb(3, 26, 50);
  color: white;
  display: grid;
  grid-template-columns: 125px 400px 200px 300px;
  padding: 150px;
  grid-auto-flow: column;
  column-gap: 5px;
  transition: background-color 0.3s; /* Smooth background color transition */
}

footer:hover {
  background-color: rgb(5, 30, 60); /* Darken on hover */
}

/* Responsive Design Adjustments */
@media (max-width: 1450px) {
  footer {
    grid-template-columns: 1fr; /* Stack all items vertically */
    grid-template-rows: repeat(5, auto);
    text-align: center;
    padding: 80px 20px;
  }

  .logo-container {
    margin-bottom: 20px; /* Bring the logo closer to the top */
    justify-content: center; /* Align the logo centrally */
  }

  .footer-social ul {
    flex-direction: column; /* Stack social icons vertically on smaller screens */
    gap: 10px;
  }

  .footer-social ul li a {
    font-size: 22px;
  }

  .container1, .container2, .container3 , .footer-social {
    text-align: left;
  }

  /* Adjust font size for better visibility */
  .footer-logo {
    width: 60px;
  }
}

@media (max-width: 768px) {
  footer {
    padding: 30px 20px; /* Reduce padding for smaller screens */
  }

  .footer-logo {
    width: 50px; /* Adjust logo size */
  }

  .footer-social ul {
    flex-direction: column;
    gap: 15px;
  }

  .footer-social ul li a {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  footer {
    padding: 20px 10px; /* Reduce padding further */
  }

  .footer-logo {
    width: 50px; /* Maintain small logo size */
  }

  .footer-social ul li a {
    font-size: 20px;
  }
}
/* Adjust layout for screens larger than 1000px */
@media (min-width: 1000px) {
  footer {
      display: flex;
      flex-wrap: nowrap; /* Prevent wrapping */
      justify-content: space-between; /* Distribute sections horizontally */
  }
  footer .footer-section {
      flex: 1; /* Ensure sections share available space equally */
      text-align: left; /* Align content to the left for horizontal layout */
  }
}
/* Base styles for the footer */
footer {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  gap: 20px; /* Adds spacing between sections */
  justify-content: center; /* Centers content by default */
  align-items: flex-start; /* Aligns sections at the top */
  padding: 20px;
}

/* Footer section styles (ensure all sections are consistent) */
footer .footer-section {
  flex: 1; /* Sections take equal space on larger screens */
  min-width: 250px; /* Ensures sections don't shrink too small */
  text-align: center; /* Centers content on smaller screens */
}

/* Adjust layout for larger screens (above 1000px) */
@media (min-width: 1000px) {
  footer {
      flex-wrap: nowrap; /* No wrapping, horizontal layout */
      justify-content: space-between; /* Space out sections evenly */
  }

  footer .footer-section {
      text-align: left; /* Align content to the left for horizontal layout */
  }
}

/* Adjust layout for medium screens (600px - 999px) */
@media (max-width: 999px) and (min-width: 600px) {
  footer {
      justify-content: space-around; /* Balanced layout for medium screens */
  }

  footer .footer-section {
      text-align: center; /* Center-align for better readability */
  }
}

/* Adjust layout for small screens (below 600px) */
@media (max-width: 599px) {
  footer {
      flex-direction: column; /* Stacks sections vertically */
      align-items: center; /* Centers each section */
  }

  footer .footer-section {
      width: 100%; /* Each section takes full width */
      text-align: center; /* Center text for smaller screens */
  }
}
