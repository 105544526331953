/* Donate.css */
.donate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f9fc;
  }
  
  .donate-content {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .donate-title {
    font-size: 2rem;
    color: #3a3a3a;
    margin-bottom: 1rem;
  }
  
  .donate-text {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .qr-code-container {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 1.5rem;
  }
  
  .qr-code {
    width: 200px;
    height: auto;
  }
  
  .share-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .share-button:hover {
    background-color: #0056b3;
  }
  
  .donate-footer-text {
    font-size: 0.9rem;
    color: #888;
    margin-top: 1.5rem;
  }
  /* General Styles */
.donate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f9fc;
  padding: 20px;
}

.donate-content {
  text-align: center;
  width: 100%;
  max-width: 450px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.donate-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.donate-text, .donate-footer-text {
  font-size: 1rem;
  color: #555;
  margin: 0.5rem 0;
}

.qr-code-container {
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.qr-code-container:hover {
  transform: scale(1.05);
}

.qr-code {
  width: 100%;
  max-width: 250px;
  height: auto;
  transition: 0.3s;
}

.share-button {
  padding: 12px 25px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.share-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

/* Responsive Styling */
@media (max-width: 768px) {
.donate-title {
  font-size: 1.8rem;
}

.donate-text, .donate-footer-text {
  font-size: 0.95rem;
}

.share-button {
  padding: 10px 20px;
  font-size: 0.9rem;
}
}

@media (max-width: 480px) {
.donate-content {
  padding: 15px;
}

.qr-code-container {
  padding: 10px;
}

.donate-title {
  font-size: 1.6rem;
}

.donate-text, .donate-footer-text {
  font-size: 0.85rem;
}

.share-button {
  padding: 8px 15px;
  font-size: 0.85rem;
}
}
