.hero, .services-section, .our-cases-area {
    padding: 60px 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .services-container, .cases-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-card, .cause-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card:hover, .cause-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #1fa67b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 auto 10px;
    font-size: 1.5rem;
  }
  
  .progress-bar {
    height: 8px;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 10px 0;
  }
  
  .progress {
    height: 100%;
    background-color: #1fa67b;
    border-radius: 4px;
  }
  
  .donate-button {
    background-color: #1fa67b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .donate-button:hover {
    background-color: #17a369;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #1fa67b;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .services-container, .cases-container {
      flex-direction: column;
      align-items: center;
    }
  }
  /* Services Section */
.services-section {
    text-align: center;
    padding: 60px 0;
    background-color: #f0f2f5;
    animation: slideUp 1s ease-in-out;
  }
  
  .services-section h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #212529;
  }
  
  .section-subtitle {
    color: #28a745;
    font-size: 1.2em;
    margin-bottom: 40px;
  }
  
  .services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .service-card {
    background-color: #fff;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
  
  .highlighted-card {
    background-color: #28a745;
    color: white;
  }
  
  .icon-circle {
    background-color: #28a745;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 0 auto 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-circle i {
    color: white;
    font-size: 24px;
  }
  .section-padding30 {
    padding: 60px 0;
}

.section-bg2 {
    background-color: #f5f7fa;
}

.section-tittle span {
    font-size: 1.2rem;
    color: #ff5e5e;
    font-weight: 600;
}

.section-tittle h2 {
    font-size: 2.5rem;
    color: #333;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.single-job-items {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.single-job-items:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.company-img img {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
}

.job-description h4 {
    font-size: 1.8rem;
    color: #333;
    font-weight: 700;
}

.job-description p {
    color: #777;
    margin-bottom: 20px;
}

.job-description .btn {
    background-color: #ff5e5e;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.job-description .btn:hover {
    background-color: #e74c3c;
}
.counter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: linear-gradient(135deg, #4CAF50, #81C784); /* Gradient background */
  color: white; /* White text */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s; /* Smooth transition */
}

.counter-item {
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s; /* Transition for hover effects */
}

.counter-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.counter-number {
  font-size: 36px;
  font-weight: bold;
  animation: countUp 2s ease-in-out forwards; /* Animation for counting */
}

.counter-label {
  font-size: 16px;
}

/* Keyframes for number counting animation */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.services-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .services-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 100%;
    max-width: 400px;
  }
}
.highlighted-card {
  background-color: #28a745; /* Green background */
  color: white; /* White text color */
}

.highlighted-card h3,
.highlighted-card p {
  color: white; /* Ensure text color is white inside the highlighted card */
}

.service-logo {
   /* Ensure the image does not overflow */
  margin-top: 15px;
}
/* Gallery Section */
.gallery-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.gallery-section h2 {
  font-size: 2.5rem;
  color: #212529;
  margin-bottom: 30px;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.gallery-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Services Section */
.services-section {
  text-align: center;
  padding: 60px 0;
  background-color: #f0f2f5;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #212529;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 100%;
    max-width: 400px;
  }

  .gallery-container {
    flex-direction: column;
    align-items: center;
  }

  .gallery-image {
    max-width: 90%;
    margin-bottom: 20px;
  }
}
/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #f0f2f5;
  text-align: left;
  flex-wrap: wrap;
}

.hero-text {
  max-width: 50%;
  padding: 20px;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-buttons {
  display: flex;
  gap: 15px;
}

.donate-button {
  background-color: #1fa67b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.donate-button:hover {
  background-color: #17a369;
}

.call-us {
  font-size: 1.2rem;
  color: #1fa67b;
  text-decoration: none;
}

.hero-image {
  max-width: 45%;
  display: block;
  padding: 20px;
}

.hero-image img {
  width: 100%;
  border-radius: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    max-width: 100%;
    padding: 15px;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.1rem;
  }

  .hero-image {
    max-width: 100%;
    margin-top: 20px;
  }

  .hero-buttons {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 40px 10px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .donate-button {
    padding: 12px 25px;
    font-size: 1rem;
  }

  .call-us {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.8rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .donate-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
.services-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f0f2f5;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #212529;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.service-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 250px; /* Ensures cards grow and shrink based on screen size */
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.highlighted-card {
  background-color: #28a745;
  color: white;
}

.highlighted-card h3,
.highlighted-card p {
  color: white;
}

.icon-circle {
  background-color: #28a745;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-circle i {
  color: white;
  font-size: 24px;
}

.service-logo {
  margin-top: 15px;
}

@media (max-width: 1024px) {
  .services-container {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 90%;
    max-width: 400px;
  }
}
/* Mission Section */
.mission-section {
  display: flex;
  align-items: center;
  padding: 60px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.mission-image {
  width: 50%;
  padding-right: 50px;
}

.mission-image img {
  width: 100%;
  height: 60vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.mission-image img:hover {
  transform: scale(1.05);
}

.mission-text {
  width: 50%;
  text-align: left;
  animation: slideInRight 1s ease-in-out;
}

.mission-section h2 {
  font-size: 2.5em;
  color: #28a745;
  margin-bottom: 10px;
  animation: fadeInUp 1s ease-in-out;
}

.mission-section h3 {
  font-size: 2em;
  color: #212529;
  margin-bottom: 20px;
}

.mission-section p {
  color: #6c757d;
  font-size: 1.2em;
  margin-bottom: 30px;
  line-height: 1.6;
}

.about-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: #0056b3;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mission-section {
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }

  .mission-image, .mission-text {
    width: 100%;
    padding: 0;
  }

  .mission-image img {
    margin-bottom: 20px;
    height: 40vh; /* Adjust image size for smaller screens */
  }

  .mission-text h2 {
    font-size: 2em; /* Adjust text size for smaller screens */
  }

  .mission-text h3 {
    font-size: 1.8em;
  }

  .mission-text p {
    font-size: 1.1em;
  }

  .about-button {
    width: 100%;
    padding: 12px;
    font-size: 1.2em;
  }
}
/* Gallery Section */
.gallery-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.gallery-section h2 {
  font-size: 2.5rem;
  color: #212529;
  margin-bottom: 30px;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 0 auto;
}

.gallery-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .gallery-container {
    justify-content: center;
  }

  .gallery-image {
    max-width: 280px;
  }
}

@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column;
    align-items: center;
  }

  .gallery-image {
    max-width: 90%;
    margin-bottom: 20px;
    border-radius: 15px;  /* Slightly more rounded corners */
  }
}

@media (max-width: 480px) {
  .gallery-section h2 {
    font-size: 2rem;
  }

  .gallery-container {
    gap: 10px;
  }

  .gallery-image {
    max-width: 100%;
  }
}
/* Counter Section */
.counter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: linear-gradient(135deg, #4CAF50, #81C784); /* Gradient background */
  color: white; /* White text */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  border-radius: 10px;
}

.counter-item {
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s; /* Transition for hover effects */
}

.counter-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.counter-number {
  font-size: 36px;
  font-weight: bold;
  animation: countUp 2s ease-in-out forwards; /* Animation for counting */
}

.counter-label {
  font-size: 16px;
}

/* Keyframes for number counting animation */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles for Counter Section */
@media (max-width: 1024px) {
  .counter-section {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .counter-item {
    margin-bottom: 20px;
  }

  .counter-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .counter-section {
    padding: 15px;
  }

  .counter-number {
    font-size: 30px; /* Reduce font size for smaller screens */
  }

  .counter-label {
    font-size: 14px; /* Adjust label size for smaller screens */
  }

  .counter-item {
    width: 100%;
    max-width: 200px; /* Ensure counter items don't stretch too wide */
    margin: 10px 0;
  }
}
