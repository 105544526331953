/* Existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  overflow-x: hidden;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Top Header */
.top-header {
  display: flex;
  padding: 10px 50px;
  background-color: #fff;
  font-size: 14px;
  color: #6c757d;
  justify-content: space-between;
  align-items: center;
  
}

.contact-info span {
  margin-right: 15px;
}

.language-select {
  padding: 5px;
}

.social-icons i {
  margin: 0 5px;
  color: #6c757d;
}

.social-icons i:hover {
  color: #007bff;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  height: 40px;
}

.nav-menu {
  list-style: none;
  display: flex;
}

.nav-menu li {
  margin-right: 25px;
  color: #212529;
  cursor: pointer;
  
}

.nav-menu li:hover {
  color: #007bff;
}

.donate-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.donate-button:hover {
  background-color: #218838;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #fff;
  min-height: 100vh;
}

.hero-text {
  max-width: 50%;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-text h1 {
  font-size: 3em;
  color: #212529;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2em;
  color: #6c757d;
  margin-bottom: 30px;
}

.hero-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-buttons .donate-button {
  margin-right: 15px;
}

.call-us {
  color: #28a745;
  text-decoration: none;
  font-size: 1.2em;
}

.call-us:hover {
  text-decoration: underline;
}

.hero-image img {
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Services Section */
.services-section {
  text-align: center;
  padding: 60px 0;
  background-color: #f0f2f5;
  animation: slideUp 1s ease-in-out;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #212529;
}

.section-subtitle {
  color: #28a745;
  font-size: 1.2em;
  margin-bottom: 40px;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.service-card {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.highlighted-card {
  background-color: #28a745;
  color: white;
}

.icon-circle {
  background-color: #28a745;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-circle i {
  color: white;
  font-size: 24px;
}

/* Mission Section (Replicated) */
.mission-section {
  text-align: center;
  padding: 60px;
  background-color: #fff;
}

.mission-section h2 {
  font-size: 2.5em;
  color: #28a745;
}

.mission-section h3 {
  font-size: 2em;
  color: #212529;
  margin-bottom: 20px;
}

.mission-section p {
  color: #6c757d;
  font-size: 1.2em;
  margin-bottom: 30px;
}

.about-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
}

.about-button:hover {
  background-color: #0056b3;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-image img {
    width: 100%;
    height: auto;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
  }
}
.service-logo{
  vertical-align: middle;
  height: 250px;
  width: 200px;
   
}
.social-icons{
  margin-right: 300px;
}
/* Mission Section */
.mission-section {
  display: flex;
  align-items: center;
  padding: 60px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.mission-image {
  width: 50%;
  padding-right: 50px;
}

.mission-image img {
  width: 100%;
  height: 60vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.mission-image img:hover {
  transform: scale(1.05);
}

.mission-text {
  width: 50%;
  text-align: left;
  animation: slideInRight 1s ease-in-out;
}

.mission-section h2 {
  font-size: 2.5em;
  color: #28a745;
  margin-bottom: 10px;
  animation: fadeInUp 1s ease-in-out;
}

.mission-section h3 {
  font-size: 2em;
  color: #212529;
  margin-bottom: 20px;
}

.mission-section p {
  color: #6c757d;
  font-size: 1.2em;
  margin-bottom: 30px;
  line-height: 1.6;
}

.about-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: #0056b3;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mission-section {
    flex-direction: column;
    text-align: center;
  }

  .mission-image, .mission-text {
    width: 100%;
    padding: 0;
  }

  .mission-image img {
    margin-bottom: 20px;
  }
}

.our-cases-area {
  background-color: #f9f9f9;
  padding: 30px;
}

.single-cases {
  margin-bottom: 40px;
  transition: all 0.3s ease-in-out;
}

.single-cases:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.cases-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.cases-caption {
  padding: 20px;
}

.cases-caption h3 {
  font-weight: bold;
  margin-top: 0;
}

.bar-progress {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.barfiller {
  width: 0%;
  height: 100%;
  background-color: #09cc7f;
  transition: width 1s ease-in-out;
}

.tipWrap {
  position: relative;
  top: -30px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  color: #09cc7f;
}

.prices {
  margin-top: 20px;
}

.prices p {
  margin-bottom: 0;
}

.prices span {
  font-weight: bold;
}
.get-involved-section {
  padding: 60px 0;
  background-color: #f8f9fa;
  text-align: center;
}

.get-involved-section h2 {
  font-size: 2.5em;
  color: #212529;
  margin-bottom: 40px;
}

.get-involved-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.get-involved-card {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.get-involved-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.get-involved-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.get-involved-button:hover {
  background-color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .get-involved-container {
    flex-direction: column;
    align-items: center;
  }

  .get-involved-card {
    width: 100%;
    max-width: 400px;
  }
}
.featured-job-area {
 
  background-size: cover;
  background-position: center;
  padding: 30px;
}

.featured-job-area:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.single-job-items {
  transition: all 0.3s ease-in-out;
}

.single-job-items:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.job-items {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-items:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.company-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.company-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job-tittle {
  margin-left: 20px;
}

.job-tittle h4 {
  font-weight: bold;
  margin-top: 0;
}

.job-tittle ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.job-tittle ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.job-tittle ul li i {
  font-size: 18px;
  margin-right: 10px;
}
a{
  color: black;
  font-size: small;
  text-decoration: none;
  
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  overflow-x: hidden;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Top Header */
.top-header {
  display: flex;
  padding: 10px 50px;
  background-color: #fff;
  font-size: 14px;
  color: #6c757d;
  justify-content: space-between;
  align-items: center;
}

.contact-info span {
  margin-right: 15px;
}

.language-select {
  padding: 5px;
}

.social-icons i {
  margin: 0 5px;
  color: #6c757d;
}

.social-icons i:hover {
  color: #007bff;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  height: 40px;
}

.nav-menu {
  list-style: none;
  display: flex;
}

.nav-menu ul {
  display: flex;
  gap: 20px;
}

.nav-menu li {
  color: #212529;
  cursor: pointer;
}

.nav-menu li:hover {
  color: #007bff;
}

.donate-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.donate-button:hover {
  background-color: #218838;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 4px;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
}

.nav-menu.active {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: white;
  padding: 20px;
}

.nav-menu.active ul {
  flex-direction: column;
  gap: 10px;
}

.nav-menu.active li {
  color: white;
}

.nav-menu.active li:hover {
  color: #007bff;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 20px;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 10px;
  }

  .hamburger {
    display: flex;
  }

  .nav-menu.active {
    display: block;
  }
}
/* Top Header */
.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: #fff;
  font-size: 14px;
  color: #6c757d;
}

.contact-info {
  display: flex;
  gap: 15px; /* Space between items */
  flex-wrap: wrap; /* Wraps on smaller screens */
}

.contact-info span {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between icon and text */
}

.contact-info i {
  color: #6c757d;
  font-size: 16px;
}

.contact-info i:hover {
  color: #007bff;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons i {
  font-size: 18px;
  color: #6c757d;
}

.social-icons i:hover {
  color: #007bff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .top-header {
    flex-direction: column; /* Stack on smaller screens */
    align-items: flex-start;
    padding: 10px 20px;
  }

  .contact-info {
    flex-direction: row; /* Ensure row-wise layout */
    gap: 10px;
    width: 100%; /* Take full width */
    justify-content: flex-start;
  }

  /* Hide text and show icons only in small screens */
  .contact-info .phone-text,
  .contact-info .email-text {
    display: none; /* Hide text */
  }

  .social-icons {
    margin-top: 10px;
    justify-content: center;
    width: 100%;
    gap: 15px; /* Space between social and contact icons */
  }

  /* Show phone and email icons in the social-icons section */
  .social-icons .fa-phone,
  .social-icons .fa-envelope {
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .social-icons .fa-phone,
  .social-icons .fa-envelope {
    font-size: 20px; /* Larger icons for smaller screens */
  }
}


