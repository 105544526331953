/* Global Container */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    background: #f3f4f6;
    color: #1f2937;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Title and Description */
  .contact-title {
    font-size: 2.8rem;
    color: #111827;
    margin-bottom: 10px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
  }
  
  .contact-description {
    font-size: 1.1rem;
    color: #6b7280;
    margin-bottom: 40px;
    text-align: center;
    max-width: 600px;
    line-height: 1.6;
  }
  
  /* Contact Cards Layout */
  .contact-cards {
    display: flex;
    gap: 25px;
    justify-content: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
  
  .contact-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    width: 220px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .contact-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  /* Icons */
  .contact-icon {
    font-size: 2.5rem;
    color: #3b82f6;
    margin-bottom: 15px;
  }
  
  .contact-card h3 {
    font-size: 1.2rem;
    color: #1f2937;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .contact-card p {
    font-size: 0.95rem;
    color: #6b7280;
    line-height: 1.4;
    
  }
  
  /* Contact Form */
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 30px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .contact-input {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    color: #1f2937;
    transition: all 0.3s ease;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  .contact-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0px 0px 8px rgba(59, 130, 246, 0.3);
  }
  
  /* Message Input for textarea */
  .message-input {
    height: 150px;
    resize: vertical;
  }
  
  /* Submit Button */
  .contact-submit {
    background-color: #3b82f6;
    color: #ffffff;
    padding: 12px 25px;
    margin-top: 15px;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .contact-submit:hover {
    background-color: #2563eb;
    transform: translateY(-3px);
  }
  
  .contact-submit:active {
    background-color: #1e40af;
    transform: translateY(0);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-cards {
      flex-direction: column;
      gap: 20px;
    }
  
    .contact-card {
      width: 100%;
    }
  }
  /* Global Container */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background: #f3f4f6;
  color: #1f2937;
  font-family: 'Poppins', sans-serif;
}

/* Title and Description */
.contact-title {
  font-size: 2.8rem;
  color: #111827;
  margin-bottom: 10px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
}

.contact-description {
  font-size: 1.1rem;
  color: #6b7280;
  margin-bottom: 40px;
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
}

/* Contact Cards Layout */
.contact-cards {
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.contact-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  width: 220px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  cursor: pointer; /* Pointer cursor for clickable cards */
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
}

/* Icons */
.contact-icon {
  font-size: 2.5rem;
  color: #3b82f6;
  margin-bottom: 15px;
}

.contact-card h3 {
  font-size: 1.2rem;
  color: #1f2937;
  margin-bottom: 8px;
  font-weight: 500;
}

.contact-card p {
  font-size: 0.95rem;
  color: #6b7280;
  line-height: 1.4;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 30px;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.contact-input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  color: #1f2937;
  transition: all 0.3s ease;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
}

.contact-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0px 0px 8px rgba(59, 130, 246, 0.3);
}

/* Message Input for textarea */
.message-input {
  height: 150px;
  resize: vertical;
}

/* Submit Button */
.contact-submit {
  background-color: #3b82f6;
  color: #ffffff;
  padding: 12px 25px;
  margin-top: 15px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-submit:hover {
  background-color: #2563eb;
  transform: translateY(-3px);
}

.contact-submit:active {
  background-color: #1e40af;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-cards {
    flex-direction: column;
    gap: 20px;
  }

  .contact-card {
    width: 100%;
  }

  .contact-form {
    width: 100%;
    padding: 20px;
  }
}

  