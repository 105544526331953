/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
 }
 
 body {
    background-color: #f4f7f6;
    color: #333;
 }
 
 /* Blog Page Layout */
 .blog-page {
    padding: 20px 40px;
    max-width: 1200px;
    margin: auto;
 }
 
 /* Blog Header */
 .blog-header {
    text-align: center;
    padding: 40px 0;
 }
 
 .blog-header h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
    position: relative;
 }
 
 .blog-header h1::after {
    content: '';
    width: 80px;
    height: 3px;
    background-color: #007bff;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
 }
 
 .blog-header p {
    color: #666;
    font-size: 1.1em;
 }
 
 /* Blog Grid Layout */
 .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding-top: 20px;
 }
 
 /* Blog Card */
 .blog-card {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
 }
 
 .blog-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
 }
 
 /* Blog Image */
 .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.4s ease;
 }
 
 .blog-card:hover .blog-image {
    transform: scale(1.08);
 }
 
 /* Blog Content */
 .blog-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 
 .blog-content h3 {
    font-size: 1.6em;
    color: #333;
    margin-bottom: 12px;
    transition: color 0.3s;
 }
 
 .blog-content h3:hover {
    color: #007bff;
 }
 
 .blog-meta {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #888;
    font-size: 0.9em;
    margin-bottom: 12px;
 }
 
 .blog-meta svg {
    color: #007bff;
    margin-right: 4px;
 }
 
 /* Summary Text */
 .blog-content p {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
    transition: color 0.3s;
 }
 
 /* Read More Button */
 .read-more-btn {
    display: inline-block;
    padding: 10px 25px;
    background-color: #007bff;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    transition: background-color 0.3s, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    cursor: pointer;
    text-decoration: none;
 }
 
 .read-more-btn:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
 }
 
 /* Responsive Styles */
 @media (max-width: 768px) {
    .blog-page {
       padding: 20px;
    }
    .blog-header h1 {
       font-size: 2em;
    }
    .blog-content h3 {
       font-size: 1.4em;
    }
 }
 /* Global Styles */
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Poppins', sans-serif;
}

body {
   background-color: #f4f7f6;
   color: #333;
}

/* Blog Page Layout */
.blog-page {
   padding: 20px 40px;
   max-width: 1200px;
   margin: auto;
}

/* Blog Header */
.blog-header {
   text-align: center;
   padding: 40px 0;
}

.blog-header h1 {
   font-size: 2.5em;
   color: #333;
   margin-bottom: 10px;
   position: relative;
}

.blog-header h1::after {
   content: '';
   width: 80px;
   height: 3px;
   background-color: #007bff;
   position: absolute;
   bottom: -10px;
   left: 50%;
   transform: translateX(-50%);
}

.blog-header p {
   color: #666;
   font-size: 1.1em;
}

/* Blog Grid Layout */
.blog-grid {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
   gap: 20px;
   padding-top: 20px;
}

/* Blog Card */
.blog-card {
   background: #fff;
   border-radius: 12px;
   overflow: hidden;
   box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
   transition: transform 0.3s ease, box-shadow 0.3s ease;
   display: flex;
   flex-direction: column;
}

.blog-card:hover {
   transform: translateY(-8px);
   box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Blog Image */
.blog-image {
   width: 100%;
   height: 200px;
   object-fit: cover;
   transition: transform 0.4s ease;
}

.blog-card:hover .blog-image {
   transform: scale(1.08);
}

/* Blog Content */
.blog-content {
   padding: 20px;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.blog-content h3 {
   font-size: 1.6em;
   color: #333;
   margin-bottom: 12px;
   transition: color 0.3s;
}

.blog-content h3:hover {
   color: #007bff;
}

.blog-meta {
   display: flex;
   align-items: center;
   gap: 12px;
   color: #888;
   font-size: 0.9em;
   margin-bottom: 12px;
}

.blog-meta svg {
   color: #007bff;
   margin-right: 4px;
}

/* Summary Text */
.blog-content p {
   font-size: 1em;
   color: #555;
   line-height: 1.6;
   margin-bottom: 15px;
   transition: color 0.3s;
}

/* Read More Button */
.read-more-btn {
   display: inline-block;
   padding: 10px 25px;
   background-color: #007bff;
   color: #fff;
   font-size: 1em;
   font-weight: 600;
   text-align: center;
   border-radius: 6px;
   transition: background-color 0.3s, transform 0.3s ease;
   box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
   cursor: pointer;
   text-decoration: none;
}

.read-more-btn:hover {
   background-color: #0056b3;
   transform: translateY(-3px);
   box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
   .blog-page {
      padding: 20px;
   }
   .blog-header h1 {
      font-size: 2em;
   }
   .blog-content h3 {
      font-size: 1.4em;
   }
}
/* Blog Image */
.blog-image {
   width: 100%;
   height: auto;
   object-fit: cover;
   transition: transform 0.4s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
   .blog-page {
      padding: 20px;
   }
   .blog-header h1 {
      font-size: 2em;
   }
   .blog-content h3 {
      font-size: 1.4em;
   }
}
/* Global Styles */
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Poppins', sans-serif;
}

body {
   background-color: #f4f7f6;
   color: #333;
}

/* Blog Page Layout */
.blog-page {
   padding: 20px 40px;
   max-width: 1200px;
   margin: auto;
}

/* Blog Header */
.blog-header {
   text-align: center;
   padding: 40px 0;
}

.blog-header h1 {
   font-size: 2.5em;
   color: #333;
   margin-bottom: 10px;
   position: relative;
}

.blog-header h1::after {
   content: '';
   width: 80px;
   height: 3px;
   background-color: #007bff;
   position: absolute;
   bottom: -10px;
   left: 50%;
   transform: translateX(-50%);
}

.blog-header p {
   color: #666;
   font-size: 1.1em;
}

/* Blog Grid Layout */
.blog-grid {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
   gap: 20px;
   padding-top: 20px;
}

/* Blog Card */
.blog-card {
   background: #fff;
   border-radius: 12px;
   overflow: hidden;
   box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
   transition: transform 0.3s ease, box-shadow 0.3s ease;
   display: flex;
   flex-direction: column;
}

.blog-card:hover {
   transform: translateY(-8px);
   box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Blog Image */
.blog-image {
   width: 100%;
   height: 200px;
   object-fit: cover;
   transition: transform 0.4s ease;
}

.blog-card:hover .blog-image {
   transform: scale(1.08);
}

/* Blog Content */
.blog-content {
   padding: 20px;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.blog-content h3 {
   font-size: 1.6em;
   color: #333;
   margin-bottom: 12px;
   transition: color 0.3s;
}

.blog-content h3:hover {
   color: #007bff;
}

.blog-meta {
   display: flex;
   align-items: center;
   gap: 12px;
   color: #888;
   font-size: 0.9em;
   margin-bottom: 12px;
}

.blog-meta svg {
   color: #007bff;
   margin-right: 4px;
}

/* Summary Text */
.blog-content p {
   font-size: 1em;
   color: #555;
   line-height: 1.6;
   margin-bottom: 15px;
   transition: color 0.3s;
}

/* Read More Button */
.read-more-btn {
   display: inline-block;
   padding: 10px 25px;
   background-color: #007bff;
   color: #fff;
   font-size: 1em;
   font-weight: 600;
   text-align: center;
   border-radius: 6px;
   transition: background-color 0.3s, transform 0.3s ease;
   box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
   cursor: pointer;
   text-decoration: none;
}

.read-more-btn:hover {
   background-color: #0056b3;
   transform: translateY(-3px);
   box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
   .blog-page {
      padding: 20px;
   }
   .blog-header h1 {
      font-size: 2em;
   }
   .blog-content h3 {
      font-size: 1.4em;
   }
}
